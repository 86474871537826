.video {
    background: #ffffff;

    .container {
        padding: 0 0 30px 20px;

        .tap {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            padding-right: 20px;

            ul {
                display: flex;

                li {
                    font-size: 16px;
                    font-family: PingFangSC-Semibold, PingFang SC, PopinsSemi;
                    font-weight: 600;

                    a {
                        padding-bottom: 8px;
                        font-size: 14px;
                        font-family: PopinsSemi, Poppins;
                        font-weight: 600;
                        color: #2e384e;
                        line-height: 25px;
                        @apply relative;

                        white-space: nowrap;

                        .bar {
                            display: block;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            margin: auto;
                            width: 12px;
                            height: 3px;
                            background: #FF3E5F;
                            border-radius: 10px;
                        }
                    }
                }
            }

            .more {
                width: 52px;
                height: 24px;
                border-radius: 12px;
                text-align: center;
                line-height: 24px;
                border: 1px solid #e8e8e8;

                font-size: 10px;
                font-family: Poppins-Regular, Poppins;
                font-weight: 400;
                color: #708096;
            }
        }

        .itemTitle2 {
            padding: 0 0 16px;
            font-size: 10px;
            font-family: Popins, Poppins;
            font-weight: 400;
            color: #b8c0cb;
            line-height: 16px;
        }

        .main {


            .goodItemBox {
                width: 100%;
                display: flex;
                overflow-x: scroll;
                overflow-y: hidden;
                scrollbar-width: none;
                /* firefox */
                -ms-overflow-style: none;

                /* IE 10+ */
                .pic {
                    margin-left: 6px;



                    .item {
                        width: 162px;
                        height: 226px;
                        border-radius: 12px;
                        position: relative;



                        .top {
                            position: absolute;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            top: 6px;

                            left: 0;
                            right: 0;
                            margin: auto;

                            width: 70px;
                            height: 20px;
                            background: rgba(0, 0, 0, 0.5);
                            border-radius: 10px;

                            font-size: 11px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #ffffff;

                            .svg {
                                margin-right: 3px;
                                width: 13px;
                                height: 13px;
                            }
                        }

                        .bottom {
                            margin: auto;
                            left: 4px;
                            right: 4px;
                            bottom: 5px;
                            height: 52px;
                            background: #FFFFFF;
                            border-radius: 10px;
                            @apply absolute flex justify-start items-center;

                            .atr {
                                width: 36px;
                                height: 36px;
                                display: flex;
                                align-items: center;
                                margin-right: 4px;
                                margin-left: 4px;
                            }

                            .name {
                                width: 71px;
                                font-size: 11px;
                                font-family: PingFangSC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #ffffff;

                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                word-break: break-all;
                            }


                            ._countName {
                                font-size: 10px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #2E384E;

                            }

                            ._count {
                                font-size: 10px;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #2E384E;
                                margin-left: 5px;
                            }
                        }
                    }
                }

                :first-child {
                    margin-left: 0;
                }
            }

            .goodItemBox::-webkit-scrollbar {
                display: none;
                /* Chrome Safari */
            }

            .productItemBox {
                width: 100%;
                display: flex;
                overflow-x: scroll;
                overflow-y: hidden;
                scrollbar-width: none;
                /* firefox */
                -ms-overflow-style: none;

                /* IE 10+ */
                .pic {
                    margin-left: 6px;

                    .item {
                        width: 162px;
                        height: 226px;
                        border-radius: 12px;
                        position: relative;

                        .info {
                            width: 162px;
                            height: 40px;
                            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
                            @apply rounded-bl-[8px] rounded-br-[8px] absolute flex items-center px-[16px] justify-start left-0 right-0 bottom-0;

                            .atavarPic {
                                width: 20px;
                                height: 20px;
                                @apply flex items-center;

                            }

                            .digg {
                                margin-left: 3px;
                                font-size: 11px;
                                font-family: PingFangSC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #FFFFFF;
                                @apply flex items-center;

                                .diggCount {
                                    margin-left: 3px;
                                }


                            }

                            .play {
                                margin-left: 10px;
                                font-size: 11px;
                                font-family: PingFangSC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #00B42A;
                                @apply flex items-center;

                                .playCount {
                                    margin-left: 3px;
                                }
                            }
                        }
                    }
                }

                :first-child {
                    margin-left: 0;
                }
            }

            .productItemBox::-webkit-scrollbar {
                display: none;
                /* Chrome Safari */
            }
        }


    }
}